import { template as template_3e46666696504eff8baf18e7e0fda12b } from "@ember/template-compiler";
const SidebarSectionMessage = template_3e46666696504eff8baf18e7e0fda12b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
