import { template as template_885e7a14a0424610aa23545b6c059815 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_885e7a14a0424610aa23545b6c059815(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
