import { template as template_c3d5dc6661b94b2c813fa9397066ddd8 } from "@ember/template-compiler";
const WelcomeHeader = template_c3d5dc6661b94b2c813fa9397066ddd8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
